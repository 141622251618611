import React from 'react';
import './Benefits.css';
import timeMoney from '../../images/time-money.png';
import recipe from '../../images/recipe.png';
import ingredients from '../../images/ingredients.png';

const Benefits = () => {
    return (
        <div className="benefits">
            <div className="benefits__wrapper blocks-wrapper">
                <div className="benefits__benefits-wrapper">
                    <div className="benefits__single">
                        <h2 className='benefits__title'>Reduce Food Waste</h2>
                        <figure className='benefits__img'>
                            <img src={ingredients} alt="savor saver reduce food waste" />
                        </figure>
                        <p className='benefits__desc'>Transform Leftovers into Tomorrow’s Gourmet Delight. Savor Saver helps you reinvent the meals you love, minimizing waste while maximizing flavor.</p>
                    </div>
                    <div className="benefits__single">
                        <h2 className='benefits__title'>Save Money and Time</h2>
                        <figure className='benefits__img'>
                            <img src={timeMoney} alt="savor saver reduce food waste" />
                        </figure>
                        <p className='benefits__desc'>Delicious Meals, Less Spend. Discover how Savor Saver streamlines your meal prep, saving you time and reducing grocery bills.</p>
                    </div>
                    <div className="benefits__single">
                        <h2 className='benefits__title'>Customizable Recipes</h2>
                        <figure className='benefits__img'>
                            <img src={recipe} alt="savor saver reduce food waste" />
                        </figure>
                        <p className='benefits__desc'>Your Taste, Your Recipes. Tailor dishes to your preference with Savor Saver’s customizable recipe suggestions. Your perfect meal is just a tap away</p>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
export default Benefits;
