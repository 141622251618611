import React, { useEffect } from 'react';
import Loader from '../Loader/Loader';
import RecipeFeedback from '../RecipeFeedback/RecipeFeedback';
import RecipeDetailsCard from './RecipeDetailsCard/RecipeDetailsCard';
import RecipeIngredientsCard from './RecipeIngredientsCard/RecipeIngredientsCard';
import RecipeInstructionsCard from './RecipeInstructionsCard/RecipeInstructionsCard';
import RecipeSuggestedAlternatives from './RecipeSuggestedAlternatives/RecipeSuggestedAlternatives';
import RecipeSuggestedUserIngredientsAdjustments from './RecipeSuggestedUserIngredientsAdjustments/RecipeSuggestedUserIngredientsAdjustments';
import { useAuth } from '../../context/AuthContext';

function RecipeInstructions({
  isLoading,
  text,
  ingredients,
  recipeName,
  recipeDescription,
  numberOfServings,
  suggestedIngredients,
  suggestedAlternatives,
  onSuggestedIngredientClick,
  onSuggestedAlternativeClick,
  onSuggestedUserIngredientAdjustmentClick,
  setIsSuggestedIngredientsModalOpen,
  suggestedUserIngredientsAdjustments,
  currentIndex,
}) {
  const { currentUser } = useAuth(); // Access the current user

  useEffect(() => {
    console.log('[NOT YET IMPLEMENTED] Current User ID:', currentUser?.uid); // Log the user ID if available
  }, [currentUser]); // This effect runs whenever currentUser changes

  if (isLoading) {
    return <Loader />;
  }

  if (!text && (!ingredients || ingredients.length === 0)) {
    return (
      <div className='recipe-instructions empty'>
        No recipe generated yet. Add at least 3 ingredients to get started!
      </div>
    );
  }

  return (
    <div className='recipe-wrapper'>
      <RecipeDetailsCard
        recipeName={recipeName}
        recipeDescription={recipeDescription}
        numberOfServings={numberOfServings}
      />
      <RecipeIngredientsCard
        ingredients={ingredients}
        setIsSuggestedIngredientsModalOpen={setIsSuggestedIngredientsModalOpen}
      />
      <RecipeInstructionsCard
        text={text}
      />
      <RecipeSuggestedUserIngredientsAdjustments
        suggestedUserIngredientsAdjustments={suggestedUserIngredientsAdjustments}
        onSuggestedUserIngredientAdjustmentClick={onSuggestedUserIngredientAdjustmentClick}
      />
      <RecipeSuggestedAlternatives
        suggestedAlternatives={suggestedAlternatives}
        onSuggestedAlternativeClick={onSuggestedAlternativeClick}
      />
      <RecipeFeedback
        key={currentIndex}
        recipeData={{
          ingredients,
          text,
          recipeName,
          recipeDescription,
          numberOfServings,
          suggestedIngredients,
          suggestedAlternatives,
          suggestedUserIngredientsAdjustments,
        }}
      />
    </div>
  );
}

export default RecipeInstructions;
