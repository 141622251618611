// client/src/components/ControlButtons/ControlButtons.jsx

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ControlButtons({
    currentIndex,
    historyLength,
    navigateRecipeHistory,
    resetView,
    generateAlternativeRecipe,
    canGenerateAlternativeRecipe,
  }) {
    return (
      <div className='control-buttons'>
        <button
          className='control-buttons__pagination'
          disabled={currentIndex <= 0}
          onClick={() => navigateRecipeHistory(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          className='control-buttons__pagination'
          disabled={currentIndex >= historyLength - 1}
          onClick={() => navigateRecipeHistory(1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
        <button onClick={resetView}>
          Clear
        </button>
      </div>
    );
  }

  export default ControlButtons