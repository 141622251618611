import React from 'react';

function RecipeInstructionsCard({ text }) {
  return (
    <div className='recipe-card recipe-instructions-card recipe-instructions'>
      <h2>Instructions</h2>
      {Array.isArray(text) && text.length > 0 ? (
        <ol className="recipe-instructions__items">
          {text.map((instruction, index) => (
            instruction.length > 0 && (
              <li key={index}>{instruction}</li>
            )
          ))}
        </ol>
      ) : (
        <p>No instructions provided.</p>
      )}
    </div>
  );
}

export default RecipeInstructionsCard;
