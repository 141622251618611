import React from 'react';
import './Contact.css';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Content from '../../components/Content/Content'

const Contact = () => {
	return (
		<div className="contact-page">
			<Header/>
			<Content title={'Contact us'}
				content={
				<>
					<p>
					Feel free to reach out to us with any questions or feedback you
					may have. We value your input and are here to assist you. Your
					insights and thoughts are essential in helping us enhance your
					cooking experience with our app.
					</p>
					<p>
					<strong>savorsaverapp@gmail.com</strong>
					</p>
				</>
				}/>
			<Footer/>
		</div>
  	);
}

export default Contact;
