// src/utils/storage.js

export const loadState = (key) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        console.log('No state found in localStorage');
        return undefined; // No state in localStorage
      }
      console.log('Loaded state from localStorage');
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };
  
  export const saveState = (key, state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
      console.log('Saved state to localStorage');
    } catch (err) {
      // Handle write errors or log them
      console.error("Could not save state to localStorage:", err);
    }
  };

  export const loadPreviousIngredients = () => {
    try {
      const serializedIngredients = localStorage.getItem('previousIngredients');
      if (serializedIngredients === null) {
        return []; // Return an empty array if nothing is found
      }
      return JSON.parse(serializedIngredients);
    } catch (err) {
      console.error("Could not load previous ingredients from localStorage:", err);
      return [];
    }
  };
  
  export const savePreviousIngredients = (ingredients) => {
    try {
      const serializedIngredients = JSON.stringify(ingredients);
      localStorage.setItem('previousIngredients', serializedIngredients);
    } catch (err) {
      console.error("Could not save previous ingredients to localStorage:", err);
    }
  };
  