import React from 'react';
import './About.css';
import Cards from '../Cards/Cards'

const About = () => {
    return (
        <div className="about">
            <div className="about__wrapper blocks-wrapper">
                <div className="about__content-wrapper">
                    <div className='about__content'>
                        <h2 className='about__title'>Discover the Joy of Effortless Cooking</h2>
                        <p>With just a few taps, Savor Saver crafts a recipe tailored to your pantry. Select from hundreds of ingredients and get the recipe generated by AI in seconds! Want something different? Simply regenerate or refine the recipe to suit your palate. Experience the thrill of turning your available ingredients into a culinary delight, and share your favorite creations with friends and family!</p>
                    </div>
                    <Cards />
                </div>
            </div>
        </div>
    );
  }
  
export default About;
