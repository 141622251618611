// client/src/components/SignInWithGoogle/SignInWithGoogle.jsx

import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

function SignInWithGoogle() {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            console.log(result);
            // The signed-in user info.
            // const user = result.user;
            // ...
        }).catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.error('Error signing in with Google:', errorMessage);
        });
    };

    return (
        <button onClick={signInWithGoogle}>Sign In With Google</button>
    );
}

export default SignInWithGoogle;
