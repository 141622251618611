// src/components/RecentIngredientsModal/RecentIngredientsModal.js

import React from 'react';
import './RecentIngredientsModal.css'; // Ensure the CSS file exists and is correctly linked

function RecentIngredientsModal({ isOpen, onClose, recentIngredients, onAddIngredient }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>Recent Ingredients</h2>
        <p className="modal-instructions">Click on any ingredient to add it to your list. You can select multiple ingredients.</p>
        {recentIngredients.length > 0 ? (
          <div className="suggested-ingredient-list">
            {recentIngredients.map(ingredient => (
              <div key={ingredient} className="ingredient-item" onClick={() => onAddIngredient(ingredient)}>
                + {ingredient}
              </div>
            ))}
          </div>
        ) : (
          <div className="empty-state-message">
            No recent ingredients yet. Add some to see them here!
          </div>
        )}
        <button className="modal-close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default RecentIngredientsModal;
