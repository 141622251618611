import React from 'react';

function RecipeSuggestedUserIngredientsAdjustments({ suggestedUserIngredientsAdjustments, onSuggestedUserIngredientAdjustmentClick }) {
  return suggestedUserIngredientsAdjustments.length > 1 && (
    <div className='recipe-card recipe-suggested-alternatives'>
      <h2>Add Ingredients, Increase Possibilities</h2>
      <div className='suggested-ingredient-list'>
        {suggestedUserIngredientsAdjustments.map((adjustment, index) => (
          <div
            key={index}
            className='suggested-alternative-item'
            onClick={() => onSuggestedUserIngredientAdjustmentClick(adjustment)}
          >
            ✨ {adjustment.adjustmentDescription}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecipeSuggestedUserIngredientsAdjustments;
