import CookieConsent from "react-cookie-consent";

const CookieConsentBanner = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="myAwesomeCookieName"
            style={{ background: "#2B373B", alignItems: "center" }}
            contentStyle={{ margin: "10px 15px" }}
            buttonStyle={{ margin: "10px 15px", backgroundColor: "#ff9c00", color: "#ffffff", fontSize: "14px", padding: "9px 24px", borderRadius: "4px" }}
            expires={150}
        >
            This website uses cookies. By continuing to browse this site you are agreeing to our use of cookies.
        </CookieConsent>
    );
};

export default CookieConsentBanner;
