import './Footer.css';
import CustomPopup from '../CustomPopup/CustomPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <>
            <div className='app-footer'>
                <p>
                    Made with <span className='heart'>❤️</span> for food and AI
                    by m1337k & Karolina Guzik
                </p>
                <div className='footer-wrapper'>
                    <div className='socials'>
                        <p>Follow us on</p>
                        <a
                            href='https://facebook.com/savorsaverapp'
                            className='socials__social'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                    </div>
                    <div className='footer-menu'>
                        <CustomPopup
                            triggerText='Privacy policy'
                            modalTitle='Privacy policy'
                            modalContent={
                                <>
                                    <h3>1. General Information</h3>
                                    <p>
                                        This policy applies to the website
                                        operating at the URL: savor-saver.com.
                                        <br />
                                        The service operator and personal data
                                        administrator is: Ikagi Karolina Guzik,
                                        ul. Chełmońskiego 108D/132, 31-340
                                        Kraków.
                                        <br />
                                        Contact email address of the operator:
                                        savorsaverapp@gmail.com.
                                        <br />
                                        The operator is the administrator of
                                        your personal data in relation to the
                                        data provided voluntarily on the
                                        Website.
                                        <br />
                                        The website uses personal data for the
                                        following purposes:
                                    </p>
                                    <ul>
                                        <li>
                                            Presentation of offers or
                                            information,
                                        </li>
                                        <li>
                                            Performance of legally binding
                                            obligations on the personal data
                                            administrator as per Article 6(1)(c)
                                            of the GDPR to the extent provided
                                            for by specific regulations (e.g.,
                                            accounting).
                                        </li>
                                    </ul>
                                    <p>
                                        The website acquires information about
                                        users and their behavior in the
                                        following ways:
                                    </p>
                                    <ul>
                                        <li>
                                            Through voluntarily entered data in
                                            forms, which are entered into the
                                            Operator's systems,
                                        </li>
                                        <li>
                                            By storing cookies files ("cookies")
                                            in end devices.
                                        </li>
                                    </ul>

                                    <h3>
                                        2. Selected Data Protection Methods Used
                                        by the Operator
                                    </h3>
                                    <p>
                                        The places of logging in and entering
                                        personal data are protected at the
                                        transmission layer (SSL certificate).
                                        Thus, personal data and login data
                                        entered on the page are encrypted on the
                                        user's computer and can only be read on
                                        the target server.
                                        <br />
                                        The operator periodically changes its
                                        administrative passwords.
                                        <br />
                                        To protect data, the operator regularly
                                        makes backup copies.
                                        <br />
                                        An important element of data protection
                                        is the regular updating of all software
                                        used by the Operator to process personal
                                        data, which particularly means regular
                                        updates of software components.
                                    </p>

                                    <h3>3. Hosting</h3>
                                    <p>
                                        The website is hosted (technically
                                        maintained) on the operator's servers:
                                        Firebase.
                                        <br />
                                        The hosting company, to ensure technical
                                        reliability, conducts server-level logs.
                                        These may include:
                                    </p>
                                    <ul>
                                        <li>
                                            Resources identified by URL
                                            identifier (addresses of requested
                                            resources - pages, files),
                                        </li>
                                        <li>Time of query arrival,</li>
                                        <li>Time of sending the response,</li>
                                        <li>
                                            Name of the client station -
                                            identification performed by the HTTP
                                            protocol,
                                        </li>
                                        <li>
                                            Information about errors that
                                            occurred during the HTTP
                                            transaction,
                                        </li>
                                        <li>
                                            URL address of the page previously
                                            visited by the user (referer link) -
                                            in case the transition to the
                                            Website occurred through a link,
                                        </li>
                                        <li>
                                            Information about the user's
                                            browser,
                                        </li>
                                        <li>IP address information,</li>
                                        <li>
                                            Diagnostic information related to
                                            the process of self-ordering
                                            services through recorders on the
                                            site,
                                        </li>
                                        <li>
                                            Information related to handling
                                            electronic mail directed to and sent
                                            by the Operator.
                                        </li>
                                    </ul>

                                    <h3>
                                        4. Your Rights and Additional
                                        Information on Data Usage
                                    </h3>
                                    <p>
                                        In some situations, the Administrator
                                        has the right to transfer your personal
                                        data to other recipients, if it is
                                        necessary to fulfill the contract
                                        concluded with you or to fulfill the
                                        obligations incumbent on the
                                        Administrator. This applies to such
                                        groups of recipients:
                                    </p>
                                    <ul>
                                        <li>
                                            Hosting company on the basis of
                                            entrustment,
                                        </li>
                                        <li>Payment operators,</li>
                                        <li>
                                            Authorized employees and associates
                                            who use the data to achieve the
                                            purpose of the site,
                                        </li>
                                        <li>
                                            Companies providing marketing
                                            services for the Administrator.
                                        </li>
                                    </ul>
                                    <p>
                                        Your personal data processed by the
                                        Administrator will not be kept longer
                                        than is necessary to perform the related
                                        activities specified by separate
                                        regulations (e.g., accounting). In terms
                                        of marketing data, they will not be
                                        processed longer than 3 years.
                                        <br />
                                        You have the right to request from the
                                        Administrator:
                                    </p>
                                    <ul>
                                        <li>
                                            Access to personal data concerning
                                            you,
                                        </li>
                                        <li>Their correction,</li>
                                        <li>Deletion,</li>
                                        <li>Processing restriction,</li>
                                        <li>And data portability.</li>
                                    </ul>
                                    <p>
                                        You have the right to object to the
                                        processing of personal data for the
                                        purpose of carrying out legally
                                        justified interests pursued by the
                                        Administrator, including profiling,
                                        provided that there are no valid legally
                                        justified bases for processing,
                                        overriding your interests, rights, and
                                        freedoms, in particular establishing,
                                        investigating, or defending claims.
                                        <br />
                                        Complaints against the Administrator's
                                        actions can be lodged with the President
                                        of the Personal Data Protection Office,
                                        ul. Stawki 2, 00-193 Warsaw.
                                        <br />
                                        Providing personal data is voluntary,
                                        but necessary to service the Website.
                                        <br />
                                        In relation to you, actions may be taken
                                        consisting of automated decision making,
                                        including profiling, in order to provide
                                        services within the framework of the
                                        concluded contract and for the
                                        Administrator to conduct direct
                                        marketing.
                                        <br />
                                        Personal data is not transferred to
                                        third countries within the meaning of
                                        data protection regulations. This means
                                        that we do not transfer them outside the
                                        European Union.
                                    </p>

                                    <h3>5. Information in Forms</h3>
                                    <p>
                                        The website collects information
                                        voluntarily provided by the user,
                                        including personal data, if they are
                                        provided.
                                        <br />
                                        The website may save information about
                                        connection parameters (time marking, IP
                                        address).
                                        <br />
                                        In some cases, the website may save
                                        information facilitating the linking of
                                        data in the form with the user's email
                                        address filling in the form. In such a
                                        case, the user's email address appears
                                        inside the URL of the page containing
                                        the form.
                                        <br />
                                        Data provided in the form are processed
                                        for the purpose resulting from the
                                        function of a specific form, e.g., to
                                        carry out the process of service report
                                        handling or commercial contact, service
                                        registration, etc. Each time the context
                                        and description of the form in a
                                        readable way informs what it is used
                                        for.
                                    </p>

                                    <h3>6. Administrator's Logs</h3>
                                    <p>
                                        Information about users' behavior on the
                                        site may be logged. These data are used
                                        to administer the site.
                                    </p>

                                    <h3>7. Essential Marketing Techniques</h3>
                                    <p>
                                        The operator uses statistical analysis
                                        of site traffic through Google Analytics
                                        (Google Inc. based in the USA). The
                                        operator does not transfer personal data
                                        to the operator of this service, only
                                        anonymized information. The service is
                                        based on the use of cookies in the
                                        user's end device. In terms of
                                        information about user preferences
                                        collected by the Google advertising
                                        network, the user can view and edit
                                        information resulting from cookies using
                                        the tool:{' '}
                                        <a href='https://www.google.com/ads/preferences/'>
                                            https://www.google.com/ads/preferences/
                                        </a>
                                        <br />
                                        The operator uses the Facebook pixel.
                                        This technology means that Facebook
                                        (Facebook Inc. based in the USA) knows
                                        that a person registered in it uses the
                                        Service. In this case, it is based on
                                        data for which it itself is the
                                        administrator, the Operator does not
                                        transfer any additional personal data to
                                        Facebook. The service is based on the
                                        use of cookies in the user's end device.
                                        <br />
                                        The operator uses a Heap Analytics (an
                                        analytics service provided by Heap Inc.)
                                        - solution examining users' behavior by
                                        creating heat maps and recording
                                        behavior on the site. This information
                                        is anonymized before being sent to the
                                        service operator, so he does not know
                                        which physical person they concern. In
                                        particular, passwords and other personal
                                        data are not subject to recording.
                                        <br />
                                        The operator uses a LogRocket - solution
                                        examining users' behavior by recording
                                        behavior on the site. This information
                                        is anonymized before being sent to the
                                        service operator, so he does not know
                                        which physical person they concern.
                                        <br />
                                        The operator uses the Google AdWords.
                                        Google AdWords conversion tracking is an
                                        analytics service provided by Google
                                        Inc. that connects data from the Google
                                        AdWords advertising network with actions
                                        performed on this Application.
                                        <br />
                                    </p>
                                    <h3>8. Information about Cookies</h3>
                                    <p>The website uses cookies.</p>
                                    <p>
                                        Cookies ("cookies") are IT data, in
                                        particular text files, which are stored
                                        in the User's end device of the Website
                                        and are intended for using the website's
                                        pages. Cookies usually contain the name
                                        of the website they come from, the time
                                        of storage on the end device, and a
                                        unique number.
                                    </p>
                                    <p>
                                        The entity placing cookies on the
                                        Website User's end device and accessing
                                        them is the website operator.
                                    </p>
                                    <h3>
                                        Cookies are used for the following
                                        purposes:
                                    </h3>
                                    <ul>
                                        <li>
                                            Achieving the goals set out above in
                                            the part "Essential Marketing
                                            Techniques";
                                        </li>
                                    </ul>
                                    <p>
                                        The website uses two basic types of
                                        cookies: "session" (session cookies) and
                                        "permanent" (persistent cookies).
                                        "Session" cookies are temporary files
                                        that are stored in the User's end device
                                        until logging out, leaving the website,
                                        or turning off the software (web
                                        browser). "Permanent" cookies are stored
                                        in the User's end device for the time
                                        specified in the parameters of cookies
                                        or until they are deleted by the User.
                                    </p>
                                    <p>
                                        Web browsing software (web browser)
                                        usually by default allows the storage of
                                        cookies in the User's end device.
                                        Website users can change the settings in
                                        this regard. The web browser allows you
                                        to delete cookies. It is also possible
                                        to automatically block cookies. Detailed
                                        information on this subject is provided
                                        by the help or documentation of the web
                                        browser.
                                    </p>
                                    <p>
                                        Restrictions on the use of cookies may
                                        affect some of the functionalities
                                        available on the Website's web pages.
                                    </p>
                                    <p>
                                        Cookies placed in the Website User's end
                                        device may also be used by entities
                                        cooperating with the Website operator,
                                        in particular this applies to companies:
                                        Google (Google Inc. based in the USA),
                                        Facebook (Facebook Inc. based in the
                                        USA), Twitter (Twitter Inc. based in the
                                        USA).
                                    </p>

                                    <h3>
                                        9. Managing Cookies – How to Express and
                                        Withdraw Consent in Practice?
                                    </h3>
                                    <p>
                                        If the user does not want to receive
                                        cookies, they can change the browser
                                        settings. We reserve that disabling the
                                        handling of cookies necessary for
                                        authentication processes, security,
                                        maintaining user preferences may hinder,
                                        and in extreme cases, prevent the use of
                                        www websites.
                                    </p>
                                    <p>
                                        To manage cookie settings, select from
                                        the list below the web browser you use
                                        and follow the instructions:
                                    </p>
                                    <ul>
                                        <li>
                                            <a
                                                href='https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Edge
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href='https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Internet Explorer
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href='http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Chrome
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href='http://support.apple.com/kb/PH5042'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Safari
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href='http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Firefox
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href='http://help.opera.com/Windows/12.10/pl/cookies.html'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Opera
                                            </a>
                                        </li>
                                    </ul>
                                    <h3>Mobile devices:</h3>
                                    <ul>
                                        <li>
                                            <a
                                                href='http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Android
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href='http://support.apple.com/kb/HT1677?viewlocale=pl_PL'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Safari (iOS)
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href='http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                Windows Phone
                                            </a>
                                        </li>
                                    </ul>
                                </>
                            }
                        />
                    </div>
                </div>
                <p>
                    <span>© 2024 savor-saver.com All rights reserved.</span> |
                    Icons by{' '}
                    <a
                        target='_blank'
                        rel='noreferrer'
                        className='icons8-link'
                        href='https://icons8.com/'
                    >
                        Icons8
                    </a>
                </p>
            </div>
        </>
    );
};

export default Footer;
