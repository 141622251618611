// client/src/components/Loader/Loader.jsx

import React, { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import './Loader.css';

function Loader({ foodFacts }) {

    const [randomFact, setRandomFact] = useState(null);
  
    useEffect(() => {
      if (foodFacts.length > 0) {
        const randomIndex = Math.floor(Math.random() * foodFacts.length);
        setRandomFact(foodFacts[randomIndex]);
      }
    }, [foodFacts]);
  
    return (
      <div className='loader-container'>
        <h2>Your recipe is being generated...</h2>
        <div className='spinner-container'>
          <BeatLoader
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary-color'
            )}
          />
        </div>
        <h3 className='fact-header'>Did you know?</h3>
        {randomFact && (
          <div>
            <p className='fact-text'>{randomFact}</p>
          </div>
        )}
      </div>
    );
  }

  export default Loader