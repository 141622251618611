import Popup from 'reactjs-popup';
import './CustomPopup.css';

const CustomPopup = ({ triggerText, modalTitle, modalContent }) => {
  const overlayStyle = {
    background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
  };

  return (
    <Popup 
      trigger={<button className='modal__link'>{triggerText}</button>} 
      modal 
      overlayStyle={overlayStyle}
    >
      {(close) => (
        <div className='modal'>
          <button className='close' onClick={close}>
            &times;
          </button>
          <h2 className='modal__heading'>{modalTitle}</h2>
          <div className='modal__content'>{modalContent}</div>
        </div>
      )}
    </Popup>
  );
};

export default CustomPopup;
