import React from 'react';
import './LandingPage.css';
import Hero from '../../components/Hero/Hero';
import About from '../../components/About/About';
import Benefits from '../../components/Benefits/Benefits';
import Steps from '../../components/Steps/Steps';
import Cta from '../../components/Cta/Cta';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

const LandingPage = () => {
	return (
		<div className="landing-page">
			<Header />
			<Hero />
			<About />
			<Benefits />
			<Steps />
			<Cta />
			<Footer />
		</div>
	);
}

export default LandingPage;
