import React from 'react';
import './About.css';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Content from '../../components/Content/Content'

const About = () => {
  return (
    <div className="about-page">
        <Header/>
        <Content title={'About us'} content={
            <>
                <p>
                Explore our innovative cooking app, where culinary creativity
                meets convenience! We understand that sometimes the best dishes
                are born from what's already in your pantry. Our app is designed
                to make your cooking experience effortless and enjoyable. Simply
                input your available ingredients, and let us generate a
                personalized recipe tailored to your kitchen's inventory.
                </p>
                <p>
                Meet Marek and Karolina, the tech-savvy foodies behind this
                culinary adventure. With a deep love for both cooking and AI,
                we've combined our passions to bring you this app. Please note
                that our recipes are crafted by AI algorithms and may
                occasionally contain mistakes. However, we are committed to
                continuously improving our app to provide you with the best
                culinary experience. Embrace the joy of spontaneous cooking and
                savor the delicious surprises that await you with each use of
                our app!
                </p>
            </>
            }/>
        <Footer/>
    </div>
  );
}

export default About;
