import React from 'react';

function RecipeSuggestedAlternatives({ suggestedAlternatives, onSuggestedAlternativeClick }) {
  return suggestedAlternatives.length > 0 && (
    <div className='recipe-card recipe-suggested-alternatives'>
      <h2>Same Ingredients, Different Dish</h2>
      <div className='suggested-ingredient-list'>
        {suggestedAlternatives.map((alt, index) => (
          <div
            key={index}
            className='suggested-alternative-item'
            onClick={() => onSuggestedAlternativeClick(alt)}
          >
            ✨ {alt}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecipeSuggestedAlternatives;
