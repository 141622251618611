import React from 'react';
import Ingredient from './Ingredient/Ingredient';
import IngredientSelector from './IngredientSelector/IngredientSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import './RecipeIngredients.css'; // Make sure this points to the correct CSS file

function RecipeIngredients({
  ingredients = [],
  onAdd,
  onRemove,
  allIngredients,
  setIsRecentIngredientsModalOpen,
  isRecentIngredientsModalOpen,
  previousIngredients
}) {
  return (
      <div className="recipe-ingredients-root"> {/* Add this class */}
            {ingredients.map((ingredient, index) => (
                <div className='ingredient-list-item' key={ingredient.id}>
                    <Ingredient
                        ingredient={ingredient}
                        onRemove={onRemove}
                    />
                </div>
            ))}
            <div className="ingredient-selector-wrapper">
                {onAdd && (
                    <IngredientSelector 
                        onAdd={onAdd} 
                        allIngredients={allIngredients} 
                        className="ingredient-selector" // Add this line if you can control the className
                    />
                )}
                <button 
                    onClick={() => setIsRecentIngredientsModalOpen(!isRecentIngredientsModalOpen)} 
                    className="recent-ingredients-btn" 
                    title="Recent Ingredients">
                    <FontAwesomeIcon icon={faHistory} />
                </button>
            </div>
        </div>
    );
}

export default RecipeIngredients;
