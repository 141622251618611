// client/src/components/LoginPromptModal/LoginPromptModal.jsx

import React from 'react';
import Modal from '../Modal/Modal'; // Ensure this path is correct
import SignInWithGoogle from '../SignInWithGoogle/SignInWithGoogle'; // Ensure this path is correct
import './LoginPromptModal.css';
import { useAuth } from '../../context/AuthContext'; // Adjust the path according to your project structure

function LoginPromptModal() {
  const { isLoginModalOpen, closeLoginModal } = useAuth();

  return (
    <Modal
      isOpen={isLoginModalOpen} // Use context's state
      onClose={closeLoginModal} // Use context's function
      title="Unlock Premium Features!"
      instructions="Sign in to access premium features and take your experience to the next level."
    >
      <div className="login-prompt-content">
        <p className="login-prompt-text">Unlock premium features by signing in.</p>
        <div className="login-options">
          <SignInWithGoogle />
          {/* Add more sign-in options here */}
        </div>
      </div>
    </Modal>
  );
}

export default LoginPromptModal;
