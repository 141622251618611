import React, { useState } from 'react';
import Select from 'react-select';
import './IngredientSelector.css'; // Import the CSS file

const IngredientSelector = ({ onAdd, allIngredients }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  // Converts the array of ingredient names into the format required by react-select
  // and sorts them alphabetically by label
  const options = allIngredients
    .map(ingredient => ({
      value: ingredient,
      label: ingredient
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleChange = (option) => {
    setSelectedOption(option);
    // Calls the provided onAdd function with the selected ingredient
    if (option) {
      onAdd({ name_en: option.value });
      setSelectedOption(null);
    }
  };

  return (
    <div className='ingredient-selector'>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        placeholder='Search for ingredient'
        isClearable
        classNamePrefix='ingredient-select'
      />
    </div>
  );
};

export default IngredientSelector;
