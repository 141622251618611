// client/src/components/SuggestedIngredientsModal/SuggestedIngredientsModal.jsx

import React from 'react';
import Modal from '../Modal/Modal';
import './SuggestedIngredientsModal.css';

function SuggestedIngredientsModal({ isOpen, onClose, suggestedIngredients, onSuggestedIngredientClick }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Suggested Ingredients"
      instructions="Click on any ingredient to add it to your list. You can select multiple ingredients."
    >
      <div className="suggested-ingredient-list">
        {suggestedIngredients.map(ing => (
          <div key={ing} className="ingredient-item" onClick={() => onSuggestedIngredientClick(ing)}>
            + {ing}
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default SuggestedIngredientsModal;
