// client/src/RecipeIngredients/Ingredient/Ingredient.jsx
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

function Ingredient({ ingredient, onRemove }) {
    return (
      <div className='ingredient'>
        <span className='ingredient__name'>{ingredient.name_en}</span>
        <span
          className='ingredient__delete'
          onClick={() => onRemove(ingredient.id)}
        >
          <FontAwesomeIcon icon={faClose} />
        </span>
      </div>
    );
}

export default Ingredient;