import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { loadState, saveState, loadPreviousIngredients, savePreviousIngredients } from '../utils/storage';
import { fetchRecipe, fetchSharedRecipe } from '../services/recipeApiService'; // Adjust the path as necessary

const useRecipeGenerator = () => {
  const MIN_INGREDIENTS_REQUIRED = 3;

  const [previousIngredients, setPreviousIngredients] = useState(() => loadPreviousIngredients());
  const [isFetching, setIsFetching] = useState(false);
  const [historyState, setHistoryState] = useState(() => {
    const loadedState = loadState('recipeHistory');
    return {
      history: loadedState ? loadedState.history : [],
      currentIndex: loadedState && loadedState.history.length > 0 ? loadedState.history.length : -1,
      currentIngredients: [],
      recipeName: '',
      recipeDescription: '',
      numberOfServings: -1,
      recipeInstructions: [],
      recipeIngredients: [],
      suggestedIngredients: [],
      suggestedAlternatives: [],
    };
  });

  const fetchRecipeForCurrentConfig = useCallback(async ({ recipeName = '', adjustmentRequest = '' } = {}) => {
    console.log('Fetching recipe with ingredients:', historyState.currentIngredients.map(ingredient => ingredient.name_en));
    console.log('Fetching recipe with recipeName:', recipeName);
    console.log('Fetching recipe with adjustmentRequest:', adjustmentRequest);
    
    const ingredientNames = historyState.currentIngredients.map(ingredient => ingredient.name_en);
    
    setIsFetching(true);
    
    try {
      const data = await fetchRecipe({ ingredients: ingredientNames, recipeName, adjustmentRequest });
      
      console.log('Fetched recipe data:', data);
  
      if (data) {
        const newHistoryItem = {
          ...data,
          ingredients: historyState.currentIngredients,
        };
  
        console.log('New history item:', newHistoryItem);
        
        setHistoryState(prevState => ({
          ...prevState,
          history: [...prevState.history, newHistoryItem],
          currentIndex: prevState.history.length,
        }));

        window.heap?.track('Recipe Generated', {
            ingredients: newHistoryItem.ingredients.map(ing => ing.name_en).join(', '),
            recipeName: newHistoryItem.recipeName,
            recipeDescription: newHistoryItem.recipeDescription,
            numberOfServings: newHistoryItem.numberOfServings,
            recipeInstructions: newHistoryItem.recipeInstructions,
            recipeIngredients: newHistoryItem.recipeIngredients.join(', '),
            suggestedIngredients: newHistoryItem.suggestedIngredients.join(', '),
            suggestedAlternatives: newHistoryItem.suggestedAlternatives.join(', '),
            suggestedUserIngredientsAdjustments: Object.values(newHistoryItem.suggestedUserIngredientsAdjustments).map(adjustment => adjustment.adjustmentDescription).join(', '),
        });
      }
    } catch (error) {
      console.error('Error fetching the recipe:', error);
    } finally {
      setIsFetching(false);
    }
  }, [historyState.currentIngredients]);  

  const generateRecipe = async ({ recipeName = '', adjustmentRequest = '' } = {}) => {
    if (historyState.currentIngredients.length >= MIN_INGREDIENTS_REQUIRED) {
      await fetchRecipeForCurrentConfig({ recipeName, adjustmentRequest });
    } else {
      alert('Please add at least 3 ingredients to generate a recipe.');
    }
  };  

  const updateHistoryStateFromSharedRecipe = async (sharedId) => {
    try {
      const data = await fetchSharedRecipe(sharedId);
  
      setHistoryState({
        history: [data],
        currentIndex: 0,
        currentIngredients: data.ingredients,
      });
    } catch (error) {
      console.error('Error fetching the shared recipe:', error);
    }
  };  
  
  const resetRecipeGeneration = () => {
    setHistoryState((prevState) => ({
      ...prevState,
      currentIngredients: [],
      history: [...prevState.history, { ingredients: [] }],
      currentIndex: prevState.history.length,
    }));
  };

  const addIngredientsAndGenerateRecipe = async (adjustment) => {
    const { requiredIngredients, adjustmentDescription } = adjustment;

    // Wait for all required ingredients to be added
    for (let ingredientName of requiredIngredients) {
      await addIngredient({ name_en: ingredientName });
    }
    // Once all ingredients are added, generate the recipe with the adjustment
    await generateRecipe({ adjustmentRequest: adjustmentDescription });
  };

  const addIngredient = (ingredientObject) => {
    const isIngredientAlreadyAdded = historyState.currentIngredients.some(
      ingredient => ingredient.name_en.toLowerCase() === ingredientObject.name_en.toLowerCase()
    );

    if (!isIngredientAlreadyAdded) {
      setHistoryState(prevState => ({
        ...prevState,
        currentIngredients: [...prevState.currentIngredients, { ...ingredientObject, id: uuidv4() }],
      }));

      const updatedPreviousIngredients = [...new Set([...previousIngredients, ingredientObject.name_en])];
      setPreviousIngredients(updatedPreviousIngredients);
      savePreviousIngredients(updatedPreviousIngredients);
    }
  };

  const removeIngredient = (id) => {
    const updatedIngredients = historyState.currentIngredients.filter(
      ingredient => ingredient.id !== id
    );
    setHistoryState(prevState => ({
      ...prevState,
      currentIngredients: updatedIngredients,
    }));
  };

  // Persist recipe history in local storage
  useState(() => {
    saveState('recipeHistory', historyState);
  }, [historyState]);

  return {
    generateRecipe,
    fetchRecipeForCurrentConfig,
    addIngredient,
    removeIngredient,
    isFetching,
    historyState,
    setHistoryState,
    previousIngredients,
    resetRecipeGeneration,
    updateHistoryStateFromSharedRecipe,
    addIngredientsAndGenerateRecipe
  };
};

export default useRecipeGenerator;
