import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config'; // Adjust the import path as necessary

export const useIngredients = () => {
  const [ingredientsList, setIngredientsList] = useState([]);

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'ingredients'));
        const ingredients = snapshot.docs.map((doc) => doc.data().name_en);
        setIngredientsList(ingredients);
      } catch (error) {
        console.error('Error fetching ingredients:', error);
      }
    };

    fetchIngredients();
  }, []);

  return ingredientsList;
};
