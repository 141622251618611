import React, { useState } from 'react';
import './RecipeFeedback.css';

function RecipeFeedback({ recipeData }) {
  const [feedbackGiven, setFeedbackGiven] = useState(false);

  const handleFeedbackClick = (feedbackType) => {
    setFeedbackGiven(true);

    // Send custom event to Heap, including full recipe data
    if (window.heap) {
      window.heap.track('Recipe Feedback Given', {
        feedback: feedbackType,
        // Now using the updated structure of recipeData for event properties
        recipeName: recipeData.recipeName,
        recipeDescription: recipeData.recipeDescription,
        numberOfServings: recipeData.numberOfServings,
        recipeInstructions: recipeData.text, // Assuming 'text' contains the recipe instructions
        recipeIngredients: recipeData.ingredients.join(', '), // Assuming 'ingredients' is an array of ingredient names
        suggestedIngredients: recipeData.suggestedIngredients.join(', '),
        suggestedAlternatives: recipeData.suggestedAlternatives.join(', '),
      });
    }
  };

  return (
    <div className='recipe-feedback recipe-card'>
      <h2>Recipe Feedback</h2>
      <p>Your feedback is important to us! Let us know how this recipe worked out for you.</p>
      <div className="feedback-btn-container">
        {feedbackGiven ? (
            <p>Thank you!</p>
        ) : (
            <>
                <button
                onClick={() => handleFeedbackClick('Loved It')}
                className="feedback-btn positive-feedback"
                >
                Loved It
                </button>
                <button
                onClick={() => handleFeedbackClick('Not For Me')}
                className="feedback-btn negative-feedback"
                >
                Not For Me
                </button>
            </>
        )}
      </div>
    </div>
  );
}

export default RecipeFeedback;
