// client/src/App.js

import './App.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { loadState, saveState, loadPreviousIngredients } from './utils/storage'; // utils for saving and reading from local storage

import { useFoodFacts } from './hooks/useFoodFacts';
import { useIngredients } from './hooks/useIngredients';
import useRecipeGenerator from './hooks/useRecipeGenerator';

import CookieConsentBanner from './components/CookieConsent/CookieConsent';
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import LandingPage from './pages/LandingPage/LandingPage';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import NotFound from './pages/NotFound/NotFound';
import ControlButtons from './components/ControlButtons/ControlButtons';
import Loader from './components/Loader/Loader';
import GeneratedRecipeSection from './components/GeneratedRecipeSection/GeneratedRecipeSection';
import RecentIngredientsModal from './components/RecentIngredientsModal/RecentIngredientsModal';
import LoginPromptModal from './components/LoginPromptModal/LoginPromptModal'; // Add this import
import RecipeIngredients from './components/RecipeIngredients/RecipeIngredients';
import ShareRecipeButton from './components/ShareRecipeButton/ShareRecipeButton';
import SuggestedIngredientsModal from './components/SuggestedIngredientsModal/SuggestedIngredientsModal';

import { AuthProvider } from './context/AuthContext';

axios.defaults.baseURL =
  'https://us-central1-savor-saver2.cloudfunctions.net/api';

// eslint-disable-next-line no-unused-vars
const getOrCreateUserId = () => {
  let userId = localStorage.getItem('userId');
  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('userId', userId);
  }
  return userId;
};

function App() {
  const {
    generateRecipe,
    fetchRecipeForCurrentConfig, // If needed directly in App.js
    addIngredient,
    removeIngredient,
    isFetching,
    historyState,
    previousIngredients,
    setHistoryState,
    addIngredientsAndGenerateRecipe
  } = useRecipeGenerator();
  
  
  const showSignIn = localStorage.getItem('showSignIn') === 'true'; 
  // Flag to display sign in button
  // enable by localStorage.setItem('showSignIn', 'true'); in browser console

  const MIN_INGREDIENTS_REQUIRED = 3;

  const foodFacts = useFoodFacts();
  const ingredientsList = useIngredients();

  const [isSuggestedIngredientsModalOpen, setIsSuggestedIngredientsModalOpen] = useState(false);
  const [isRecentIngredientsModalOpen, setIsRecentIngredientsModalOpen] = useState(false);

  const canGenerateAlternativeRecipe =
    historyState.currentIndex >= 0 &&
    historyState.history[historyState.currentIndex]?.recipeName &&
    historyState.currentIngredients.length >= MIN_INGREDIENTS_REQUIRED;

  const [isGenerateButtonEnabled, setIsGenerateButtonEnabled] = useState(false);
  
  useEffect(() => {
    // Check if the event has already been sent in this session
    const eventSent = sessionStorage.getItem('eventSent') === 'true';

    if (!eventSent) {
      const userId = getOrCreateUserId(); // Get or create a unique user ID
      const isNewUser = localStorage.getItem('hasVisited') === null;

      localStorage.setItem('hasVisited', 'true');

      const recipeHistory = loadState('recipeHistory');
      const recentIngredients = loadPreviousIngredients();

      const numberOfRecipes = recipeHistory ? recipeHistory.history.length : 0;
      const numberOfRecentIngredients = recentIngredients.length;

      if (window.heap) {
        // Instead of identifying the user with the UUID, add it as a custom property
        window.heap.addUserProperties({
          customUserId: userId // Custom user ID property
        });
        
        window.heap.track('Access Recipe Generator', {
          isNewUser,
          numberOfRecipes,
          numberOfRecentIngredients,
        });
      }

      sessionStorage.setItem('eventSent', 'true');
    }
  }, []);

  useEffect(() => {
    setIsGenerateButtonEnabled(
      historyState.currentIngredients.length >= MIN_INGREDIENTS_REQUIRED
    );
  }, [historyState.currentIngredients]);

  const handleGenerateRecipeClick = () => {
    generateRecipe();
  };
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sharedId = urlParams.get('sharedId');
    if (sharedId) {
      const fetchSharedRecipe = async () => {
        try {
          const response = await axios.get(`/api/fetchShared/${sharedId}`);
          setHistoryState({
            history: [{ ...response.data }],
            currentIndex: 0,
            currentIngredients: response.data.ingredients,
          });
        } catch (error) {
          console.error('Error fetching the shared recipe:', error);
        }
      };
      fetchSharedRecipe();
    }
  }, [ setHistoryState ]);

  useEffect(() => {
    saveState('recipeHistory', historyState);
  }, [historyState]);

  const resetView = () => {
    setHistoryState((prevState) => ({
      ...prevState,
      currentIngredients: [],
      history: [
        ...prevState.history,
        {
          ingredients: [],
        },
      ],
      currentIndex: prevState.history.length,
    }));
  };

  const generateAlternativeRecipe = () => {
    fetchRecipeForCurrentConfig();
  };

  const navigateRecipeHistory = (direction) => {
    const newIndex = historyState.currentIndex + direction;
    if (newIndex >= 0 && newIndex < historyState.history.length) {
      const historyItem = historyState.history[newIndex];
      setHistoryState((prevState) => ({
        ...prevState,
        currentIndex: newIndex,
        currentIngredients: historyItem.ingredients,
      }));
    }
  };

  const handleSuggestedIngredientClick = (ingredient) => {
    const ingredientObject = { name_en: ingredient, id: uuidv4() };

    addIngredient(ingredientObject);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleSuggestedAlternativeClick = async (alternative) => {
    generateRecipe({ recipeName: alternative });
  };  

  const handleSuggestedUserIngredientAdjustmentClick = (adjustment) => {
    addIngredientsAndGenerateRecipe(adjustment);
  }; 

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='*' element={<NotFound />}/>
          <Route path="/recipe-generator" element={
            <>
              <RecentIngredientsModal
                isOpen={isRecentIngredientsModalOpen}
                onClose={() => setIsRecentIngredientsModalOpen(false)}
                recentIngredients={previousIngredients}
                onAddIngredient={(ingredientName) => addIngredient({ name_en: ingredientName })}
              />
              <LoginPromptModal /> {/* Place the modal here */}
              <Header showSignIn={showSignIn} />
              <div className='container'>
                <div className='recipe-header'>
                  <h2>Recipe Generator</h2>
                  <p>How it works? Add a minimum of 3 ingredients, click Generate Recipe, and get an idea for your next delicious meal!</p>
                  <h3>Your ingredients</h3>
                </div>
                <div className='ingredient-selection'>
                <RecipeIngredients
                  ingredients={historyState.currentIngredients}
                  onAdd={addIngredient}
                  onRemove={removeIngredient}
                  allIngredients={ingredientsList}
                  setIsRecentIngredientsModalOpen={setIsRecentIngredientsModalOpen}
                  isRecentIngredientsModalOpen={isRecentIngredientsModalOpen}
                  previousIngredients={previousIngredients}
                />
                </div>
                <button
                  className={`generate-recipe-btn ${!isGenerateButtonEnabled || isFetching ? 'disabled' : ''}`}
                  onClick={handleGenerateRecipeClick}
                  style={{ width: '100%' }}
                  disabled={!isGenerateButtonEnabled || isFetching}
                >
                  Generate Recipe
                </button>
                {
                  historyState.history.length > 0 && (
                    <div className='controls-row'>
                      <ControlButtons
                        currentIndex={historyState.currentIndex}
                        historyLength={historyState.history.length}
                        navigateRecipeHistory={navigateRecipeHistory}
                        resetView={resetView}
                        generateAlternativeRecipe={generateAlternativeRecipe}
                        canGenerateAlternativeRecipe={canGenerateAlternativeRecipe}
                      />
                    </div>)
                }
                <div className='recipe-section'>
                  {isFetching ? (
                    <Loader foodFacts={foodFacts} />
                  ) : (
                    <GeneratedRecipeSection
                      text={
                        historyState.history[historyState.currentIndex]
                          ?.recipeInstructions || ''
                      }
                      ingredients={
                        historyState.history[historyState.currentIndex]
                          ?.recipeIngredients || []
                      }
                      recipeName={
                        historyState.history[historyState.currentIndex]?.recipeName ||
                        ''
                      }
                      recipeDescription={
                        historyState.history[historyState.currentIndex]
                          ?.recipeDescription || ''
                      }
                      numberOfServings={
                        historyState.history[historyState.currentIndex]
                          ?.numberOfServings || -1
                      }
                      suggestedIngredients={
                        historyState.history[historyState.currentIndex]
                          ?.suggestedIngredients || []
                      }
                      suggestedAlternatives={
                        historyState.history[historyState.currentIndex]
                          ?.suggestedAlternatives || []
                      }
                      suggestedUserIngredientsAdjustments={
                        historyState.history[historyState.currentIndex]
                          ?.suggestedUserIngredientsAdjustments || []
                      }
                      onSuggestedIngredientClick={handleSuggestedIngredientClick}
                      onSuggestedAlternativeClick={handleSuggestedAlternativeClick}
                      onSuggestedUserIngredientAdjustmentClick={handleSuggestedUserIngredientAdjustmentClick}
                      setIsSuggestedIngredientsModalOpen={setIsSuggestedIngredientsModalOpen}
                      currentIndex={historyState.currentIndex}
                    />
                  )}
                </div>
                <br />
                {
                  historyState.currentIndex >= 0 && !isFetching && (
                    <ShareRecipeButton recipeData={{
                      sharedId: uuidv4(),
                      ingredients: historyState.currentIngredients,
                      recipeName: historyState.history[historyState.currentIndex]?.recipeName || '',
                      recipeDescription: historyState.history[historyState.currentIndex]?.recipeDescription || '',
                      numberOfServings: historyState.history[historyState.currentIndex]?.numberOfServings || -1,
                      recipeInstructions: historyState.history[historyState.currentIndex]?.recipeInstructions || [],
                      recipeIngredients: historyState.history[historyState.currentIndex]?.recipeIngredients || [],
                      suggestedIngredients: historyState.history[historyState.currentIndex]?.suggestedIngredients || [],
                      suggestedAlternatives: historyState.history[historyState.currentIndex]?.suggestedAlternatives || [],
                      suggestedUserIngredientsAdjustments: historyState.history[historyState.currentIndex]?.suggestedUserIngredientsAdjustments || {},
                    }} />
                    )
                }
                <p className="additional-note">Please note: The following recipe has been created using AI and has not been reviewed by Savor Saver for correctness or safety. It may include inaccuracies. We advise always using your own discretion when preparing dishes based on AI-generated instructions.</p>
              </div>
              <SuggestedIngredientsModal
                isOpen={isSuggestedIngredientsModalOpen}
                onClose={() => setIsSuggestedIngredientsModalOpen(false)}
                suggestedIngredients={historyState.history[historyState.currentIndex]?.suggestedIngredients || []}
                onSuggestedIngredientClick={handleSuggestedIngredientClick}
              />
              <CookieConsentBanner />
              <Footer />
            </>
          } />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
