import React from 'react';

function RecipeIngredientsCard({ ingredients, setIsSuggestedIngredientsModalOpen }) {
  return (
    <div className='recipe-card recipe-ingredients-card recipe-ingredients'>
      <h2>Ingredients</h2>
      {ingredients && (
        <>
          <ul className='recipe-ingredient-list'>
            {ingredients.map((ing, index) => (
              <li key={index}>{ing}</li>
            ))}
          </ul>
          <button
            className="see-suggested-ingredients-btn"
            onClick={() => setIsSuggestedIngredientsModalOpen(true)}
          >
            See suggested ingredients
          </button>
        </>
      )}
    </div>
  );
}

export default RecipeIngredientsCard;
