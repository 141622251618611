import React from 'react';
import './Cards.css';
import stirFried from '../../images/stir-fried-tofu-broccoli.webp';
import chiaPudding from '../../images/chia-pudding.webp';
import chessyChicken from '../../images/cheesy-chicken.webp';
import pasta from '../../images/pasta.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';

const cardItems = [
    {
        title: 'Easy Pasta Bolognese',
        link: 'https://savor-saver.com/recipe-generator/?sharedId=KXLpaGDIYoTG5Qdq9G65',
        image: pasta
    },
    {
        title: 'Stir-Fried Tofu with Broccoli',
        link: 'https://savor-saver.com/recipe-generator/?sharedId=NvKxcr5HIjojIGvlMFlZ',
        image: stirFried
    },
    {
        title: 'Blueberry Walnut Chia Pudding',
        link: 'https://savor-saver.com/recipe-generator/?sharedId=IbVq2lUSPhXQdAn7JNqi',
        image: chiaPudding
    },
    {
        title: 'Cheesy Chicken and Potato Bake',
        link: 'https://savor-saver.com/recipe-generator/?sharedId=rwniZv4hDKFFf5I4uc3G',
        image: chessyChicken
    }
]

const Cards = ({cards = cardItems}) => {
    return (
        <div className="cards">
            <h3 className='cards__title'>Discover What's Cooking</h3>
            <div className="cards__wrapper">
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className='card__wrapper'
                    >
                    {cards.map((card, index) => (
                        <SwiperSlide key={index} className="card">
                            <figure className="card__image">
                                <img src={card.image} alt={card.title} />
                            </figure>
                            <div className="card__content">
                                <h2 className="card__title">{card.title}</h2>
                                <a href={card.link} target="_self"  rel="noreferrer" className="card__link">View Recipe<FontAwesomeIcon icon={faArrowRight} /></a>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
  }
  
export default Cards;

