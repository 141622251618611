import React from 'react';
import './Steps.css';
import stepOne from '../../images/step-1.png';
import stepTwo from '../../images/step-2.png';
import stepThree from '../../images/step-3.png';
import stepFour from '../../images/step-4.png';

const Steps = () => {
    return (
        <div className="steps">
            <div className="steps__wrapper blocks-wrapper">
                <div className="steps__steps-wrapper">
                    <div className="steps__single">
                        <h2 className='steps__title'>
                            <span className='steps__title-number'>1.</span>
                            <span className='steps__title-text'>Select Ingredients</span>
                        </h2>
                        <p className='steps__content'>Start typing in what’s in your pantry. Our responsive interface will offer you matching ingredients as you go. Pick and populate your list with at least 3 ingredients to set the stage for your tailored recipe.</p>
                        <figure className='steps__img'>
                            <img src={stepOne} alt="savor saver steps" />
                        </figure>
                    </div>
                    <div className="steps__single">
                        <h2 className='steps__title'>
                            <span className='steps__title-number'>2.</span>
                            <span className='steps__title-text'>Recipe Revelation</span>
                        </h2>
                        <p className='steps__content'>Hit the 'Generate' button and let Savor Saver craft a unique recipe for you. In the brief moment it takes to create, we’ll serve up a delightful food fact to spark your culinary curiosity.</p>
                        <figure className='steps__img'>
                            <img src={stepTwo} alt="savor saver steps" />
                        </figure>
                    </div>
                    <div className="steps__single">
                        <h2 className='steps__title'>
                            <span className='steps__title-number'>3.</span>
                            <span className='steps__title-text'>Recipe Refinement</span>
                        </h2>
                        <p className='steps__content'>Got your recipe but feel it's missing a touch of pizzazz? Our app suggests complementary ingredients that can elevate your dish. Add as you wish to fine-tune the flavors to your taste.</p>
                        <figure className='steps__img'>
                            <img src={stepThree} alt="savor saver steps" />
                        </figure>
                    </div>
                    <div className="steps__single">
                        <h2 className='steps__title'>
                            <span className='steps__title-number'>4.</span>
                            <span className='steps__title-text'>Enjoy and Share Your Creation</span>
                        </h2>
                        <p className='steps__content'>Delight in the meal you’ve created and pass on the joy! Use the simple 'Copy to clipboard' function to share your recipe with friends or save it for future gastronomic endeavors.</p>
                        <figure className='steps__img'>
                            <img src={stepFour} alt="savor saver steps" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
export default Steps;
