import React from 'react';
import './Header.css';
import NavMobile from './components/NavMobile/NavMobile';
import { useAuth } from '../../context/AuthContext';

export const menuRoutes = [
    {
        title: "Home",
        href: "/",
    },
    {
        title: "Recipe Generator",
        href: "/recipe-generator",
    },
    {
        title: "About",
        href: "/about",
    },
    {
        title: "Contact",
        href: "/contact",
    }
];

function Header({ showSignIn }) {
    const { currentUser, signOut, openLoginModal } = useAuth();
    console.log("Show sign in", showSignIn);

    const handleSignOut = async () => {
        try {
            await signOut();
        } catch (error) {
            console.error("Sign out failed", error);
        }
    };

    return (
        <div className='navbar'>
            <a href='/' className='navbar-logo'>
                Savor<span>Saver</span>
            </a>
                <ul className="navbar-links">
                    {menuRoutes.map((route, idx) => {
                        return (
                            <li key={idx}>
                                <a className="navbar-link" href={route.href}>
                                    {route.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
                {showSignIn && (
                    <div className="auth-controls">
                        {currentUser ? (
                            <>
                                <span className="user-email">{currentUser.email}</span>
                                <a href="#!" onClick={handleSignOut} className="sign-out-link">Sign Out</a>
                            </>
                        ) : (
                            <button onClick={openLoginModal}>Sign In</button>
                        )}
                    </div>
                )}
            <NavMobile />
        </div>
    );
}

export default Header;
