import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config'; // Adjust the import path as necessary

export const useFoodFacts = () => {
  const [foodFacts, setFoodFacts] = useState([]);

  useEffect(() => {
    const fetchFoodFacts = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'foodFacts'));
        const facts = snapshot.docs.map((doc) => doc.data().body);
        setFoodFacts(facts);
      } catch (error) {
        console.error('Error fetching food facts:', error);
      }
    };

    fetchFoodFacts();
  }, []);

  return foodFacts;
};
