// src/firebase/config.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyD5FeDMHEtnYpHgRwoaJKGlFdkbDcJ8APo',
    authDomain: 'savor-saver2.firebaseapp.com',
    projectId: 'savor-saver2',
    storageBucket: 'savor-saver2.appspot.com',
    messagingSenderId: '951799079623',
    appId: '1:951799079623:web:a04507df11286ad48894e2',
    measurementId: 'G-5NFZVNBHKE',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Export for use in the application
export { db, auth, analytics };
