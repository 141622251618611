import React from 'react';
import './Content.css';

const Content = ({content, title}) => {
    return (
        <div className="content">
            <div className="content__wrapper blocks-wrapper">
                <div className="content__content-wrapper">
                    <h2 className='content__title'>{title}</h2>
                    {content}
                </div>
            </div>
        </div>
    );
  }
  
export default Content;
