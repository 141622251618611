// services/recipeApiService.js
import axios from 'axios';

axios.defaults.baseURL = 'https://us-central1-savor-saver2.cloudfunctions.net/api';

const fetchRecipe = async ({ ingredients, recipeName = '', adjustmentRequest = '' }) => {
  const payload = {
    ingredients: ingredients.join(', '),
    ...(recipeName && { recipeName }),
    ...(adjustmentRequest && { adjustmentRequest }),
  };

  try {
    const response = await axios.post('/api/recipe', payload);
    return response.data;
  } catch (error) {
    console.error('Error fetching the recipe:', error);
    throw error; // Re-throw the error to handle it in the hook
  }
};

const fetchSharedRecipe = async (sharedId) => {
  try {
    const response = await axios.get(`/api/fetchShared/${sharedId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching the shared recipe:', error);
    throw error; // Re-throw the error to handle it in the hook
  }
};

export { fetchRecipe, fetchSharedRecipe };
