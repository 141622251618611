import React from 'react';

function RecipeDetailsCard({ recipeName, recipeDescription, numberOfServings }) {
  return (
    <div className='recipe-card recipe-details-card'>
      <h2>Recipe Details</h2>
      <h3>{recipeName}</h3>
      <span>{recipeDescription}</span>
      <p><strong>Servings:</strong> {numberOfServings}</p>
    </div>
  );
}

export default RecipeDetailsCard;
