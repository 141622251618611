import React from 'react';
import './Hero.css';
import savorSaverImage from '../../images/savor-saver.png';

const Hero = () => {
    return (
        <div className="hero">
            <div className="hero__wrapper blocks-wrapper">
                <div className="hero__content-wrapper">
                    <h1 className='hero__title'>Craft Delicious Dishes with Ease!</h1>
                    <p className='hero__desc'>Never Wonder What's for Dinner Again. Our intuitive app empowers you to create mouth-watering meals from your pantry, making cooking a breeze.</p>
                    <div className="hero__btn-wrapper hero__btn-wrapper--desktop">
                        <a href="/recipe-generator" className='hero__btn'>Create Recipes Now</a>
                    </div>
                </div>
                <div className="hero__btn-wrapper hero__btn-wrapper--mobile">
                    <a href="/recipe-generator" className='hero__btn'>Create Recipes Now</a>
                </div>
                <figure className='hero__img'>
                    <img src={savorSaverImage} alt="savor saver" />
                </figure>
            </div>
        </div>
    );
  }
  
export default Hero;
