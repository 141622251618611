import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function ShareRecipeButton({ recipeData }) {
  const [isCopied, setIsCopied] = useState(false);
  const [canShare, setCanShare] = useState(false);
  const sharedId = recipeData.sharedId;
  const shareableLink = `${window.location.origin}/recipe-generator/?sharedId=${sharedId}`;

  useEffect(() => {
    setIsCopied(false);
    if (navigator.share) {
      setCanShare(true);
    } else {
      setCanShare(false);
    }
  }, [recipeData.recipeName]);

  const makeApiCall = async () => {
    try {
      await axios.post('/api/share', { ...recipeData, sharedId });
      console.log('Recipe shared successfully!');
    } catch (error) {
      console.error('Error sharing the recipe:', error);
    }
  };

  const handleShare = async () => {
    if (canShare) {
      try {
        await navigator.share({
          title: recipeData.recipeName,
          text: 'Check out this delicious recipe!',
          url: shareableLink,
        });
        // Make the API call after successful sharing
        await makeApiCall();
      } catch (error) {
        console.error('Error with sharing:', error);
      }
    }
  };

  const handleCopy = async () => {
    setIsCopied(true);
    // Make the API call when link is copied
    await makeApiCall();
  };

  return (
    <>
      {canShare ? (
        <button onClick={handleShare} className='button__share'>
          <FontAwesomeIcon icon={faShareAlt} /> Share recipe
        </button>
      ) : (
        <CopyToClipboard text={shareableLink} onCopy={handleCopy}>
          <button className='button__copy'>
            <FontAwesomeIcon icon={faCopy} /> {isCopied ? "Link copied!" : "Copy recipe link"}
          </button>
        </CopyToClipboard>
      )}
    </>
  );
}

export default ShareRecipeButton;
