import React from 'react';
import './NotFound.css';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Content from '../../components/Content/Content'

const NotFound = () => {
  return (
    <div className="404-page">
        <Header/>
        <Content title={'Error 404'} content={
            <p>Ups, page not found</p>
            }/>
        <Footer/>
    </div>
  );
}

export default NotFound;
