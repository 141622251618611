import React from 'react';
import './Cta.css';

const Cta = () => {
    return (
        <div className="cta">
            <div className="cta__wrapper blocks-wrapper">
                <div className="cta__content-wrapper">
                    <h2 className='cta__title'>Cook Smart, Live Fully: Every Ingredient Has a Story</h2>
                    <p className='cta__desc'>Ready to write the next chapter in your culinary journey? Our platform is your companion in the kitchen, helping you craft delectable meals while celebrating the ingredients you love. With 'Savor Saver', every dish tells a story of creativity, care, and joy.</p>
                    <div className="cta__btn-wrapper cta__btn-wrapper--desktop">
                        <a href="/recipe-generator" className='cta__btn'>Start Your Culinary Adventure</a>
                    </div>
                    <p className='cta__additional-content'>Proudly serving up innovation with a dash of heart. Brought to you by the passionate foodies at Savor Saver. Want to get in touch? Contact us at <strong>savorsaverapp@gmail.com</strong>!</p>
                </div>
            </div>
        </div>
    );
  }
  
export default Cta;
